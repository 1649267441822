<template>
  <input v-model="inputValue"
         @keypress="validate"
         @keydown.tab.enter="nextAction"
         @focus="checkFocus"
         autocomplete="off"
         @blur="checkMarketInput"
         :disabled="disabled"/>
</template>

<script>
import { includes, isEqual, uniq } from 'lodash';

export default {
  name: 'InputLottery',
  data() {
    return {
      indicatorPlace: null,
      blockedByMarket: false,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      // required: true,
    },
    inputIndex: {
      type: Number,
      default: 0,
    },
    maxInputLength: {
      type: Number,
      required: false,
    },
    maxNumbers: {
      type: Number,
      required: false,
    },
    validateNumbers: {
      type: Function,
    },
    focus: {
      type: Function,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newInputValue) {
        this.$emit('update-input', {
          value: newInputValue,
          index: this.inputIndex,
          indicatorPlace: this.indicatorPlace,
        });
      },
    },
  },
  methods: {
    /**
     * check is input valid
     * check is number
     * check have correct length or lower the max numbers
     * @param evt
     */
    validate(evt) {
      this.indicatorPlace = evt.target.selectionStart;
      const end = evt.target.selectionEnd;
      let isValid = this.isNumber(evt);
      if (isValid) {
        if (this.maxInputLength) {
          const afterRemoveSelections = end - this.indicatorPlace;
          isValid = evt.target.value.length < this.maxInputLength
          || this.maxInputLength - afterRemoveSelections < this.maxInputLength;
        }
        if (this.maxNumbers && isValid) {
          isValid = this.validateNumbers(evt);
        }
      }
      if (!isValid) {
        evt.preventDefault();
      }
    },
    /**
     * check for numbers
     * @param evt
     * @returns {boolean}
     */
    isNumber(evt) {
      const event = (!evt) ? window.event : evt;
      const keyCode = (event.which) ? event.which : event.keyCode;
      return (!(keyCode > 31 && (keyCode < 48 || keyCode > 57)));
    },
    /**
     * if we got focus function here we will trigger it
     */
    checkFocus() {
      if (this.focus) {
        this.focus(this.inputIndex);
      }
    },
    checkMarketInput(evt) {
      if (this.$store.getters.selectedEvent.bet?.betType === '1233') {
        const uniqArray = uniq(this.$store.getters.selectedNumbers);
        const equal = isEqual(this.$store.getters.selectedNumbers, uniqArray);
        console.log('equal', equal, this.$store.getters.selectedNumbers, uniqArray);
        const pickedNumbers = evt.target.id === 'bonusBall_0'
          ? this.$store.getters.selectedNumbers : this.$store.getters.bonusBalls;
        this.blockedByMarket = includes(pickedNumbers, evt.target.value);
        if (this.blockedByMarket || !equal) {
          document.getElementById(evt.target.id).select();
        } else {
          this.$emit('next-action', evt);
        }
      }
    },
    nextAction(evt) {
      if (this.$store.getters.selectedEvent.bet?.betType !== '1233') {
        this.$emit('next-action', evt);
      } else {
        this.checkMarketInput(evt);
      }
    },
  },
  watch: {
    /**
     * on change disabled prop we will reset input value
     * @param newValue
     */
    disabled(newValue) {
      if (newValue) {
        this.$emit('update-input', {
          value: '',
          index: this.inputIndex,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  .inputs-box-inputs & {
    background-color: #FFFFFF;
    border: 1px solid #CFD8DC;
    text-align: center;
    width: 55px;
    &[disabled] {
      background-color: #EBEBE4;
    }
    &:focus {
      outline: 1px solid #1F9BDE;
    }
  }
}
</style>
