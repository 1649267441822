<template>
  <div class="preview-betting">
    <div class="title"
         v-if="selectedEvent.event">
      <span>{{selectedEvent.event.tournament.name}}</span>
      <span>{{formatDate}}</span>
    </div>
    <div class="title bet"
         v-if="selectedEvent.bet">
      <span>{{selectedEvent.bet.specialValue
        ? `${selectedEvent.bet.name} ${selectedEvent.bet.specialValue}`
        : selectedEvent.bet.name}}</span>
    </div>
    <div class="outcomes"
         v-if="selectedEvent.bet">
      <div class="outcome"
           v-for="(outcome, key) in sortOutcomes"
           :key="key"
           @click="['832', '833', '834', '1232', '1233'].indexOf(selectedEvent.bet.betType) >= 0
           ? null : addOutcome(outcome)"
           :class="{
             active: selectedEvent.outcome && selectedEvent.outcome.shortcut  === outcome.shortcut,
             special: ['832', '833', '834', '1232', '1233'].indexOf(selectedEvent.bet.betType) < 0
             }">
        <div class="outcome-type">
          <span>{{outcome.shortcut}}</span>
        </div>
        <div class="outcome-odd">
          <span>{{outcome.shortName}}</span>
          <span>{{outcome.odd.toFixed(2)}}</span>
        </div>
      </div>
    </div>
    <div class="error"
         v-if="selectEventError && selectEventError.name !== 'outcome'">
      {{selectEventError.message}}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatFns } from '@/utility';
import EventBus from '@/store/EventBus';
import sortDataByProperty from '@/utility/sortDataByProperty';

export default {
  name: 'PreviewBettingArea',
  computed: {
    ...mapGetters([
      'selectedEvent',
      'selectEventError',
      'selectedNumbers',
      'bonusBalls',
    ]),
    formatDate() {
      return formatFns('DD.MM.YYYY. HH:mm', this.selectedEvent.event.eventStartTimeUTC);
    },
    sortOutcomes() {
      return sortDataByProperty(this.selectedEvent.bet.outcomes);
    },
  },
  methods: {
    ...mapActions(['setSelectedEvent']),
    addOutcome(outcome) {
      if (outcome.shortcut === null) {
        return;
      }
      this.setSelectedEvent({
        name: 'outcome',
        data: outcome,
      });
      EventBus.$emit('updateInputs', {
        event: this.selectedEvent.event,
        bet: this.selectedEvent.bet,
        outcome: this.selectedEvent.outcome,
        selectedNumbers: this.selectedNumbers,
        bonusBalls: this.bonusBalls,
      });
      document.getElementById('outcome_0').select();
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-betting {
  span {
    opacity: .9;
  }
}
.title,
.outcome,
.error {
  height: 33px;
  margin-top: 7px;
}
.bet,
.outcome-type {
  background-color: #F2F5F7;
  text-transform: capitalize;
}
.bet,
.outcome-type,
.outcome-odd {
  border-radius: 2px;
}
.title,
.error {
  align-items: center;
  display: flex;
  justify-content: center;
  span:first-child {
    padding-right: 10px;
  }
}
.bet {
  margin-bottom: 7px;
}
.outcomes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  .outcome {
    display: grid;
    grid-template-columns: repeat(2, 36px calc(100% - 38px));
    grid-column-gap: 2px;
    margin-top: 0 !important;
    .outcome-type {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .outcome-odd {
      align-items: center;
      border: 1px solid #CFD8DC;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
    }
    &.active {
      .outcome-type,
      .outcome-odd {
        background-color: #5DAF64;
        span {
          color: #fff;
          opacity: .9;
        }
      }
    }
    &.special {
      cursor: pointer;
    }
  }
}
</style>
