import { mapActions, mapGetters } from 'vuex';
import filter from 'lodash/filter';
import { includes } from 'lodash';
import Bet from '@/model/Bet';
import EventBus from '@/store/EventBus';

export default {
  data() {
    return {
      standards: ['832', '833', '834', '1232', '1233'],
    };
  },
  computed: {
    ...mapGetters({
      selectedEvent: 'selectedEvent',
      tickets: 'betslip/tickets',
      rules: 'rules',
      translate: 'translate',
      activeEdit: 'activeEdit',
      editBet: 'betslip/editBet',
      selectedNumbers: 'selectedNumbers',
      bonusBalls: 'bonusBalls',
      futureBetsActive: 'futureBetsActive',
      futureBetsValue: 'futureBetsValue',
    }),
  },
  methods: {
    ...mapActions({
      setSelectedEvent: 'setSelectedEvent',
      setSelectEventError: 'setSelectEventError',
      addBet: 'betslip/addBet',
      setRandomActive: 'setRandomActive',
      setActiveEdit: 'setActiveEdit',
      updateBet: 'updateBet',
      setFullOffer: 'setFullOffer',
      resetAll: 'resetAll',
      setEditBet: 'betslip/setEditBet',
    }),
    isStandard() {
      return includes(this.standards, this.selectedEvent.bet.betType);
    },
    checkTypeOfBet() {
      return this.isStandard() ? 'standard' : 'special';
    },
    async checkBet() {
      const typeOfBeth = await this.checkTypeOfBet();
      let valid = true;
      const validNumberOfTickets = this.tickets.length < this.rules.maxBetNumber.value;
      if (!validNumberOfTickets) {
        this.busServiceSendMessage({
          action: 'Dialog.ShowModal',
          data: {
            action: '7S:Dialog.ShowModal',
            message: `${this.translate('betslip_maxBetCountIs')} ${this.rules.maxBetNumber.value}`,
            type: 'warning',
            delay: 3000,
          },
        });
      } else if (this.tickets.length && typeOfBeth !== 'standard') {
        const haveTickets = filter(this.tickets,
          (ticket) => ticket.id === this.selectedEvent.outcome.id
          && !this.isStandard());
        valid = haveTickets.length === 0;
        if (!valid && this.activeEdit?.id === haveTickets[0].id) {
          valid = true;
        }
      }
      return valid && validNumberOfTickets;
    },
    async addIntoBetslip() {
      try {
        const validBet = await this.checkBet();
        if (!validBet) {
          return;
        }
        const betData = new Bet({
          event: this.selectedEvent.event,
          bet: this.selectedEvent.bet,
          outcome: this.selectedEvent.outcome,
          selectedNumbers: this.selectedNumbers?.slice(),
          bonusBalls: this.bonusBalls,
          payment: this.activeEdit
            ? this.activeEdit.payment
            : this.rules.minBetAmount.value.toFixed(2),
          futureBets: this.futureBetsActive ? +this.futureBetsValue : 1,
        });

        if (this.selectedEvent.bet.webCodeGenerated) {
          betData.webCodeGenerated = true;
        }

        this.setRandomActive(false);
        if (this.activeEdit) {
          this.updateBet(betData);
        } else {
          this.addBet(betData);
        }
        this.setEditBet(null);
        EventBus.$emit('reset-inputs');
        this.resetAll();
        document.getElementById('event_0').select();
      } catch (e) {
        if (this.selectedEvent?.event && this.selectedEvent?.bet) {
          document.getElementById('bet_0').select();
        } else {
          setTimeout(() => {
            document.getElementById('event_0').select();
          }, 100);
        }
      }
    },
  },
};
