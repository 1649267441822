<template>
  <div class="inputs-betting-area"
       v-if="events">
    <template v-for="(input, index) in inputs">
      <component :key="`${index}`"
                 :is="getProperInputBox(input.typeOfInputBox, input.name)"
                 v-on:add-bet="futureBetsActive ? focusFutureBet() : addIntoBetslip()"
                 :ref="input.name"
                 :name="input.name"
                 :prefix="input.refPrefix"
                 :type="input.type"
                 :search-prop="input.searchProp"
                 :items="getProperItems(input.name)"
                 :label="translate(input.key)"
                 :input-info="selectedEvent.outcome
              && input.name === 'outcome'
              ? selectedEvent.outcome.odd.toFixed(2) : ''"
                 :input-numbers="setOutcomeNumbers(input.name)"
                 :input-box-type="input.typeOfInputBox"
                 :numbers="'selectedNumbers'"
                 :max-numbers="selectedEvent.bet
              && selectedEvent.event.markets['832'].maxBalls"
                 :max-input-length="input.max">
      </component>
    </template>
    <template v-if="randomActive">
      <RandomInputBox class="standard-random"
                      v-on:add-bet="futureBetsActive ? focusFutureBet() : addIntoBetslip()"
                      name="standard"
                      :is-bonus-ball="isBonusBall"
                      :label="translate('general_random')"
                      :outcome-numbers="getRandomValues('maxSelections')"
                      :max-numbers="getRandomValues('maxBalls')"/>
    </template>
    <template v-if="selectedEvent.bet && isBonusBall">
      <GroupInputBox
        :is="getProperInputBox(bonusBall.typeOfInputBox, bonusBall.name)"
        v-on:add-bet="futureBetsActive ? focusFutureBet() : addIntoBetslip()"
        :ref="bonusBall.name"
        :name="bonusBall.name"
        :prefix="bonusBall.refPrefix"
        :type="bonusBall.type"
        :search-prop="bonusBall.searchProp"
        :items="getProperItems(bonusBall.name)"
        :label="translate(bonusBall.key)"
        :input-numbers="setOutcomeNumbers(bonusBall.name)"
        :input-box-type="bonusBall.typeOfInputBox"
        :numbers="'bonusBalls'"
        :max-numbers="selectedEvent.event.markets[selectedEvent.bet.betType].maxBalls"
        :max-input-length="bonusBall.max"
        :disabled="!selectedNumbers.length && selectedEvent.bet.betType !== '1232'"
        :input-info="`1-${selectedEvent.bet.maxBalls}`"
      />
    </template>
    <FutureBetsInputBox
      v-if="futureBetsActive"
      class="standard-future-bet"
      v-on:update-future-bet="valueOfFutureBets = $event"
      v-on:add-bet="addIntoBetslip"
      name="standard-future-bets"
      :future-bets-value="valueOfFutureBets"
      :label="translate('general_futureBets')"
      :max-numbers="maxNumbers"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SingleInputBox from '@/components/SingleInputBox';
import GroupInputBox from '@/components/GroupInputBox';
import RandomInputBox from '@/components/RandomInputBox';
import addBet from '@/mixins';
import FutureBetsInputBox from '@/components/FutureBetsInputBox';
import { findIndex, filter, includes } from 'lodash';
import EventBus from '@/store/EventBus';

export default {
  name: 'InputsBettingArea',
  components: {
    FutureBetsInputBox,
    RandomInputBox,
    SingleInputBox,
    GroupInputBox,
  },
  mixins: [addBet],
  data() {
    return {
      inputs: [
        {
          name: 'event',
          key: 'general_eventID',
          refPrefix: 'event_',
          type: 'number',
          typeOfInputBox: 'single',
          searchProp: 'lotteryDisplayId',
          max: 14,
        },
        {
          name: 'bet',
          key: 'general_betID',
          refPrefix: 'bet_',
          type: 'number',
          typeOfInputBox: 'single',
          searchProp: 'shortcut',
          max: 3,
        },
        {
          name: 'outcome',
          key: 'general_outcomeID',
          refPrefix: 'outcome_',
          type: 'string',
          typeOfInputBox: 'single-group',
          searchProp: 'shortcut',
          max: 2,
        },
      ],
      bonusBall: { // additional drum bonus ball 834
        name: 'bonusBall',
        key: 'nb_bonusBall',
        refPrefix: 'bonusBall_',
        type: 'number',
        typeOfInputBox: 'group',
        max: 2,
      },
      betNotFound: 'shop_betIsNotFound',
      outcomeNotFound: 'shop_outcomeIsNotInOffer',
      outcomeError: false,
      pickedNumbers: {},
      hitBonusBallsTypes: ['834', '1232', '1233'],
    };
  },
  computed: {
    ...mapGetters({
      events: 'events',
      selectEventError: 'selectEventError',
      randomActive: 'randomActive',
      tournament: 'tournament',
    }),
    valueOfFutureBets: {
      get() {
        return this.futureBetsValue;
      },
      set(newFutureBetValue) {
        this.setFutureBetsValue(newFutureBetValue);
      },
    },
    maxNumbers() {
      const {
        numOfUpcomingEvents,
        futureBets,
      } = this.tournament(this.selectedEvent.event.tournament.id);
      const numOrder = findIndex(filter(this.events,
        (event) => event.tournament.id === this.selectedEvent.event.tournament.id),
      (eventIndex) => eventIndex.eventId === this.selectedEvent.event.eventId);
      const reduceUpcominEvents = numOfUpcomingEvents - numOrder;
      return reduceUpcominEvents < futureBets ? reduceUpcominEvents : futureBets;
    },
    isBonusBall() {
      return includes(this.hitBonusBallsTypes, this.selectedEvent.bet.betType);
    },
  },
  methods: {
    ...mapActions({
      updateSelectedNumbers: 'updateSelectedNumbers',
      setBonusBalls: 'setBonusBalls',
      busServiceSendMessage: 'busServiceSendMessage',
      setFutureBetsActive: 'setFutureBetsActive',
      setFutureBetsValue: 'setFutureBetsValue',
    }),
    getProperItems(name) {
      let items = null;
      switch (name) {
        case 'event':
          items = this.events;
          break;
        case 'bet':
          items = this.selectedEvent.event?.markets;
          break;
        case 'outcome':
          items = this.selectedEvent.bet?.outcomes;
          break;
        // no default
      }
      return items;
    },
    /**
     * load proper component
     * @param inputTypeBox
     * @param name
     * @returns {string}
     */
    getProperInputBox(inputTypeBox, name) {
      let type = 'SingleInputBox';
      switch (inputTypeBox) {
        case 'group':
          type = 'GroupInputBox';
          break;
        case 'single-group':
          if (this.setOutcomeNumbers(name) > 1) {
            type = 'GroupInputBox';
          }
          break;
        // no default
      }
      return type;
    },
    /**
     * set proper input field inside component
     * @param name
     * @returns {number}
     */
    setOutcomeNumbers(name) {
      let number = 1;
      if (name === 'outcome') {
        switch (this.$store.getters.selectedEvent.bet?.betType) {
          case '832':
          case '833':
          case '834':
          case '1233':
            number = Object.keys(this.$store.getters.selectedEvent.bet.outcomes).length;
            break;
          // no default
        }
      } else if (name === 'bonusBall') {
        number = 1;
      }
      return number;
    },
    /**
     * set proper values for standard random
     */
    getRandomValues(prop) {
      return prop === 'maxBalls'
        ? this.selectedEvent.bet[prop] : Object.keys(this.selectedEvent.bet.outcomes).length;
    },
    focusFutureBet() {
      this.$nextTick(() => {
        document.getElementById('standard-future-bets').select();
      });
    },
  },
  mounted() {
    EventBus.$on('toggleFutureBets', () => {
      const activeFutureBets = this.selectedEvent.event ? !this.futureBetsActive : false;
      this.setFutureBetsActive(activeFutureBets);
      if (!this.futureBetsActive) {
        this.setFutureBetsValue('1');
        document.getElementById('event_0').select();
      }
    });
    EventBus.$on('editFuture', (num) => {
      this.setFutureBetsActive(true);
      this.setFutureBetsValue(num);
    });
  },
};
</script>

<style lang="scss" scoped>
.inputs-betting-area {
  display: flex;
  flex-direction: column;
  .event {
    order: 1;
  }
  .bet {
    order: 2;
  }
  .standard-random {
    order: 3;
  }
  .outcome {
    order: 4;
  }
  .bonusBall{
    order: 5;
  }
  .standard-future-bet{
    order: 6;
  }
}
.outcome-error {
  margin-top: 7px;
}
</style>
