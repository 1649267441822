<template>
  <div class="home">
    <Header></Header>
    <div class="betting-betslip-box">
      <BettingArea></BettingArea>
      <div class="betslip-box">
        <BetslipC :betslip-types="setBetslipTypes"
                  v-if="appShown"/>
      </div>
    </div>
  </div>
</template>

<script>

import { BetslipC, eventBus } from '@nsftx/games-sdk-js';
import Header from '@/components/Header';
import BettingArea from '@/components/BettingArea';
import EventBus from '@/store/EventBus';
import { mapGetters } from 'vuex';
import forEach from 'lodash/forEach';

export default {
  name: 'home',
  components: {
    BettingArea,
    Header,
    BetslipC,
  },
  computed: {
    ...mapGetters(['appShown']),
    setBetslipTypes() {
      return [
        {
          type: 3,
          value: 'single',
          name: this.$store.getters.translate('general_single'),
          active: true,
        },
      ];
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.$store.dispatch('handleBusMessage', { eventName: 'Slave.Shown' });
    });
    eventBus.$on('underMinPayment', () => {
      setTimeout(() => {
        let sum = 0;
        let betInvalid = false;
        const minBetPayment = this.$store.getters.config.rules.minBetAmount.value;
        const totalPayment = this.$store.getters['betslip/totalPayment'];
        forEach(this.$store.getters['betslip/tickets'], (bet) => {
          sum += +bet.payment;
          if (minBetPayment > bet.payment) {
            betInvalid = true;
          }
        });
        if (sum !== totalPayment || betInvalid) {
          this.$store.dispatch('busServiceSendMessage', {
            action: 'Dialog.ShowModal',
            data: {
              action: '7S:Dialog.ShowModal',
              message: `${this.$store.getters.translate('betslip_minBetAmount')} ${minBetPayment}`,
              type: 'warning',
              delay: 3000,
            },
          });
        }
      }, 150);
    });
    eventBus.$on('overMaxPayment', () => {
      this.$store.dispatch('busServiceSendMessage', {
        action: 'Dialog.ShowModal',
        data: {
          action: '7S:Dialog.ShowModal',
          message: `${this.$store.getters.translate('betslip_maxBetAmount')} ${this.$store.getters.config.rules.maxBetAmount.value}`,
          type: 'warning',
          delay: 3000,
        },
      });
    });
    this.$store.dispatch('setFullOffer', false);
    this.$store.dispatch('toggleShopSettings', true);
    EventBus.$emit('slaveShown', 'event');
  },
  beforeDestroy() {
    EventBus.$off('arrowHandling');
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  .betting-betslip-box {
    height: calc(100% - 35px);
    display: grid;
    grid-template-columns: 70.83333% 29.16667%;
    .betslip-box {
      height: 100%;
      position: relative;
    }
  }
}
</style>
