<template>
<div class="full-offer-markets"
     v-if="activeStandard">
  <div class="full-offer-markets-header"
  :class="{'bottom-margin': getStandardsMarkets.length === 1}">
    <span>{{ getCategory }}</span>
    <span>{{ getTournament }}</span>
    <span>{{ getDateTime() }}</span>
    <span>{{ getDateTime(true) }}</span>
  </div>
  <div class="full-offer-markets-nav-standard"
       ref="full-offer-markets-nav-standard"
       v-if="getStandardsMarkets.length > 1">
    <button v-for="(market, indexMarket) in getStandardsMarkets"
            :key="indexMarket"
            @click="activeStandard = market.betType"
            :class="{'active': market.betType === activeStandard}">
      {{ market.shortcut }}. {{ market.shortName }}
    </button>
  </div>
  <div class="full-offer-markets-body"
       :style="{height: `calc(100% - 32px - ${navHeight})`}">
    <vue-scroll ref="vs">
      <FullOfferMarketsStandard @addToBetslip="addToBetslip"
                                :selected-numbers="selectedNumbers"
                                :markets="getStandardsMarkets"
                                :active-standard="activeStandard"/>
      <FullOfferMarketsSpecial @addToBetslip="addToBetslip"
                               :markets="getSpecialsMarkets" />
    </vue-scroll>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { filter, orderBy } from 'lodash';
import { formatFns } from '@/utility';
import FullOfferMarketsStandard from '@/components/FullOfferMarketsStandard';
import FullOfferMarketsSpecial from '@/components/FullOfferMarketsSpecial';
import addBet from '@/mixins';

export default {
  name: 'FullOfferMarkets',
  components: { FullOfferMarketsSpecial, FullOfferMarketsStandard },
  mixins: [addBet],
  data() {
    return {
      activeStandard: '',
      navHeight: '0px',
    };
  },
  computed: {
    ...mapGetters({
      getAvailableMarkets: 'getAvailableMarkets',
    }),
    getCategory() {
      return this.selectedEvent.event.category.shortName;
    },
    getTournament() {
      return this.selectedEvent.event.tournament.shortName;
    },
    getStandardsMarkets() {
      return orderBy(filter(this.getAvailableMarkets,
        (market) => this.standards.indexOf(market.betType) >= 0), ['position']);
    },
    getSpecialsMarkets() {
      return filter(this.getAvailableMarkets,
        (market) => this.standards.indexOf(market.betType) < 0);
    },
  },
  methods: {
    ...mapActions({
      updateSelectedEvent: 'updateSelectedEvent',
    }),
    getDateTime(time = false) {
      const format = time ? 'HH:mm' : 'DD-MM-YYYY';
      return formatFns(format, this.selectedEvent.event.eventStartTimeUTC);
    },
    addToBetslip(data) {
      const { activeMarket, activeOutcome } = data;
      let pickedOutcome = {};
      if (activeOutcome) {
        pickedOutcome = activeOutcome;
      } else {
        const pickedOutcomes = activeMarket.betType === '1232'
          ? [this.selectedEvent.event.markets[this.activeStandard].outcomes['1232/wns:hit:1:1']]
          : filter(activeMarket.outcomes,
            (outcome) => outcome.concreteSelection === this.selectedNumbers.length);
        if (!pickedOutcomes.length) {
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        pickedOutcome = pickedOutcomes[0];
      }
      this.updateSelectedEvent({
        event: this.selectedEvent.event,
        bet: activeMarket,
        outcome: pickedOutcome,
        selectedNumbers: this.selectedNumbers,
        bonusBalls: this.bonusBalls,
      });
      this.$nextTick(() => {
        this.addIntoBetslip();
      });
    },
  },
  mounted() {
    this.activeStandard = this.isStandard && this.activeEdit ? this.activeEdit.betType : '832';
    this.$nextTick(() => {
      if (this.$refs['full-offer-markets-nav-standard']?.clientHeight) {
        this.navHeight = `${this.$refs['full-offer-markets-nav-standard']?.clientHeight}px`;
      } else {
        this.navHeight = '10px';
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.full-offer-markets {
  .full-offer-markets-header {
    display: flex;
    color: #fff;
    background: #38414E;
    font-size: 12px;
    font-weight: bold;
    border-radius: 2px;
    padding: 10px;
    &.bottom-margin {
      margin-bottom: 10px;
    }
    span {
      padding: 0 4px;
      &:not(&:last-child) {
        border-right: 1px solid;
      }
    }
  }
  .full-offer-markets-nav-standard {
    padding: 6px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 6px;
    > button {
      background-color: transparent;
      border: 1px solid #e0e3e4;
      border-radius: 6px;
      padding: 6px 12px;
      &.active {
        background-color: #1F9BDE;
        color: #fff;
      }
    }
  }
  .full-offer-markets-body {
    //height: calc(100% - 32px - 10px);
  }
}
</style>
