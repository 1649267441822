<template>
  <div class="inputs-box random">
    <div class="inputs-box-name">{{label}}</div>
    <div class="inputs-box-inputs">
      <InputLottery :value="randomValue"
                    @update-input="updateValue"
                    @next-action="handleTabEnter"
                    :max-input-length="1"
                    :validate-numbers="validateNumber"
                    :max-numbers="outcomeNumbers"
                    :id="name"
                    :disabled="disabled || !$store.getters.internetConnection"/>
    </div>
  </div>
</template>

<script>
import { filter } from 'lodash';
import InputLottery from '@/components/InputLottery';
import { getRandomNumbers } from '@/utility';

export default {
  name: 'RandomInputBox',
  data() {
    return {
      randomValue: '',
    };
  },
  components: {
    InputLottery,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    outcomeNumbers: {
      type: Number,
      required: true,
    },
    maxNumbers: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: true,
    },
    isBonusBall: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    validateNumber(event) {
      return +event.key <= this.outcomeNumbers;
    },
    updateValue(event) {
      if (event.value) {
        this.createNumbersArray(+event.value);
      } else {
        this.$store.dispatch('setSelectedEvent', {
          name: 'outcome',
          data: null,
        });
      }
    },
    handleTabEnter(event) {
      if (event.target.value) {
        this.$emit('add-bet');
      }
    },
    createNumbersArray(numb) {
      if (!numb) {
        return;
      }
      const { betType } = this.$store.getters.selectedEvent.bet;
      if (betType !== '1232') {
        const numbers = getRandomNumbers(numb, this.maxNumbers);
        this.$store.dispatch('updateSelectedNumbers', {
          value: numbers,
          index: null,
        });
        this.$store.dispatch('setSelectedEvent', {
          name: 'outcome',
          data: filter(this.$store.getters.selectedEvent.bet.outcomes,
            (outcome) => +outcome.name === numbers.length)[0],
        });
      } else {
        this.$store.dispatch('setSelectedEvent', {
          name: 'outcome',
          data: this.$store.getters.selectedEvent.bet.outcomes['1232/wns:hit:1:1'],
        });
      }
      if (this.isBonusBall) {
        const bonusBalls = getRandomNumbers(1,
          this.$store.getters.selectedEvent.event.markets[betType].maxBalls);
        this.$store.dispatch('setBonusBalls', {
          value: bonusBalls,
          index: null,
        });
      }
    },
  },
  mounted() {
    if (this.focus) {
      const { name } = this;
      document.getElementById(name).select();
    }
  },
};
</script>

<style lang="scss" scoped>
.inputs-box {
  display: flex;
}
.inputs-box-name {
  text-transform: capitalize;
  width: 102px;
}
.inputs-box-name,
.inputs-info {
  align-items: center;
  display: flex;
}
 .inputs-box-name,
 .inputs-info {
   margin-top: 7px;
   height: 33px;
 }
</style>
