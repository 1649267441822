<template>
  <div class="markets-specials-wrapper">
    <div class="market-special"
         v-for="(market, index) in objectOrder(markets)"
         :key="`special-${index}`"
         :class="[{'two-col': market.betType === '823' || market.betType === '830'},
         `special-${market.betType}`]">
      <div class="market-header">
        {{market.shortcut}} {{market.shortName}}
      </div>
      <div class="market-body">
        <div class="market-outcome"
             v-for="(outcome, outcomeKey) in objectOrder(market.outcomes)"
             :key="`outcome-${outcomeKey}`"
             :class="{active: activeOutcome === outcome.id}"
             @click.stop="addToBetslip(market, outcome)">
          <span>
            <span>{{outcome.shortcut}}</span>
            <span>{{outcome.odd.toFixed(2)}}</span>
          </span>
          <span class="market-outcome-small">({{outcome.shortName}})</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash';

export default {
  name: 'FullOfferMarketsSpecial',
  props: {
    markets: {
      type: Array,
    },
  },
  computed: {
    activeOutcome() {
      return this.$store.getters.selectedEvent.outcome?.id;
    },
  },
  methods: {
    addToBetslip(activeMarket, activeOutcome) {
      this.$store.dispatch('updateSelectedNumbers');
      this.$store.dispatch('setBonusBalls');
      this.$nextTick(() => {
        this.$emit('addToBetslip', {
          activeMarket,
          activeOutcome,
        });
      });
    },
    objectOrder(item) {
      return orderBy(item, 'position');
    },
  },
};
</script>

<style lang="scss" scoped>
.markets-specials-wrapper {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 16px;
  .market-special {
    .market-header {
      background: #f2f5f7;
    }
    .market-body {
      display: grid;
      grid-gap: 6px;
      grid-template-columns: repeat(1, 1fr);
      .market-outcome {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        &:hover,
        &.active {
          background: #5DAF64;
        }
        .market-outcome-small {
          font-size: 12px;
        }
      }
    }
    &.special-823 {
      .market-outcome {
        > span:first-child {
          align-items: center;
          display: flex;
          flex-direction: column;
          font-size: 13px;
          justify-content: center;
        }
        .market-outcome-small {
          font-size: 10px;
        }
      }
    }
    &:not(&.special-823) {
      .market-outcome {
        > span:first-child {
          > span:first-child:after {
            content: ' - ';
          }
        }
      }
    }
    .market-header,
    .market-body,
    .market-outcome {
      padding: 6px;
    }
    &.two-col {
      .market-body {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .market-special,
  .market-outcome {
    border: 1px solid #e0e3e4;
  }
}
</style>
