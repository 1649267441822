<template>
  <div class="full-offer">
    <section class="search-box">
      <input type="text"
             ref="fullOfferSearch"
             id="fullOfferSearch"
             v-model="searchValue"
             autocomplete="off"
             :placeholder="translate('general_search')">
      <em class="icon icon-close-a"
          @click="closeFullOffer"></em>
    </section>
    <section class="full-offer-box">
      <FullOfferMarkets v-if="selectedEvent.event"
                        @closeFullOffer="closeFullOffer"/>
      <FullOfferEvents v-else
                       @updateSearch="searchValue = $event"
                       :search-value="searchValue"/>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/store/EventBus';
import FullOfferEvents from '@/components/FullOfferEvents';
import FullOfferMarkets from '@/components/FullOfferMarkets';

export default {
  name: 'FullOffer',
  components: { FullOfferMarkets, FullOfferEvents },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    ...mapGetters([
      'translate',
      'searchedFullOfferValue',
      'selectedEvent',
    ]),
    searchValue: {
      get() {
        return this.searchedFullOfferValue;
      },
      set(value) {
        this.setSearchedFullOfferValue(value);
      },
    },
  },
  methods: {
    ...mapActions([
      'setSearchedFullOfferValue',
      'setFullOffer',
      'updateSelectedEvent',
      'toggleShopSettings',
      'setActiveEdit',
      'resetAll',
    ]),
    closeFullOffer() {
      this.setFullOffer(false);
      EventBus.$emit('selectEvent');
      EventBus.$emit('reset-inputs');
      this.resetAll();
      this.toggleShopSettings(true);
      this.setActiveEdit(null);
    },
  },
  mounted() {
    if (!this.selectedEvent.event) {
      EventBus.$emit('reset-inputs');
      this.resetAll({ skipSetFullOffer: true, skipEditOnBetslip: true });
    }
    this.toggleShopSettings(false);
    this.$refs.fullOfferSearch.select();
  },
};
</script>

<style lang="scss" scoped>
.full-offer {
  background: #fff;
  bottom: 0;
  left: 0;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  .search-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 33px;
    input {
      background-color: #FFFFFF;
      border: 1px solid #CFD8DC;
      height: 100%;
      padding: 0 10px;
      width: calc(100% - 40px);
      &:focus {
        outline: 1px solid #1F9BDE;
      }
    }
    em {
      cursor: pointer;
      font-size: 20px;
    }
  }
  .full-offer-box {
    height: calc(100% - 33px);
    padding-top: 10px;
    .full-offer-markets {
      height: 100%;
    }
  }
}
</style>
