<template>
  <vue-scroll ref="vs">
    <div class="full-offer-tags"
         v-if="tagsTournaments">
      <button class="tag"
              v-for="(tag, tagIndex) in tagsTournaments"
              :key="tagIndex"
              :class="{'active': tag.name === searchValue
                  || (typeof tag.shortcut === 'number'
        ? tag.shortcut.toString().toLowerCase()
        : tag.shortcut.toLowerCase()) === searchValue.toLowerCase()}"
              @click="setSearchedValue(tag.name)">
        <div class="tag-id">{{tag.shortcut}}</div>
        <div class="tag-name">{{tag.name}}</div>
      </button>
    </div>
    <div class="full-offer-events"
         ref="fullOfferEvents"
         v-if="fullOfferFiltered.length">
      <div v-for="(event, index) in fullOfferFiltered"
           class="full-offer-event"
           :class="{'active': index === selectedIndex}"
           :key="index"
           :id="`full-offer-event-${index}`"
           @click="addEvent(event, index)">
        <div class="display-id">{{event.lotteryDisplayId}}</div>
        <div class="name">{{event.tournament.name}}</div>
        <div class="date">{{formatEventDate(event.eventStartTimeUTC, 'DD.MM.YYYY.')}}</div>
        <div class="time">{{formatEventDate(event.eventStartTimeUTC, 'HH:mm')}}</div>
      </div>
    </div>
    <div class="message"
         v-else>
      {{translate(message)}}
    </div>
  </vue-scroll>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/store/EventBus';
import { formatFns } from '@/utility';

export default {
  name: 'FullOfferEvents',
  data() {
    return {
      selectedIndex: 0,
      message: 'admin_noDataForSelectionParametars',
    };
  },
  props: {
    searchValue: {
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      'translate',
      'fullOfferFiltered',
      'tagsTournaments',
    ]),
  },
  methods: {
    ...mapActions([
      'setFullOffer',
      'updateSelectedEvent',
    ]),
    formatEventDate(date, format) {
      return formatFns(format, date);
    },
    setSearchedValue(tournament) {
      const searchValue = this.searchValue === tournament ? '' : tournament;
      this.$emit('updateSearch', searchValue);
    },
    addEvent(event, index) {
      this.selectedIndex = index;
      this.updateSelectedEvent({
        data: {
          event,
          bet: null,
          outcome: null,
          selectedNumbers: [],
          bonusBalls: [],
        },
      });
      this.$emit('updateSearch', '');
      // this.setFullOffer(false);
      // this.$nextTick(() => {
      //   console.dir(document.getElementById('bet_0'));
      //   document.getElementById('bet_0').select();
      // });
    },
  },
  mounted() {
    EventBus.$on('arrowHandling', (event) => {
      if (event === 'ArrowUp') {
        if (this.selectedIndex !== 0) {
          this.selectedIndex -= 1;
          this.$refs.vs.scrollBy(
            {
              dy: -40,
            },
            200,
          );
        }
      } else if (event === 'ArrowDown') {
        if (this.fullOfferFiltered.length > (this.selectedIndex + 1)) {
          this.selectedIndex += 1;
          this.$refs.vs.scrollBy(
            {
              dy: 40,
            },
            200,
          );
        }
      } else {
        this.addEvent(this.fullOfferFiltered[this.selectedIndex], this.selectedIndex);
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('arrowHandling');
  },
};
</script>

<style lang="scss" scoped>
.full-offer-tags {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 10px;
  .tag {
    background: #F2F5F7;
    border-radius: 2px;
    color: #455A64;
    display: flex;
    > div {
      align-items: center;
      display: flex;
      height: 30px;
      justify-content: center;
    }
    .tag-id {
      width: 30px;
    }
    .tag-name {
      border-left: 1px solid rgba(255, 255, 255, .5);
      width: calc(100% - 30px);
    }
  }
}
.full-offer-event {
  align-items: center;
  border-top: 1px solid #CFD8DC;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0 10px;
  > div {
    &.display-id {
      width: 100px;
    }
    &.time {
      font-family: primary-font-bold;
      text-align: right;
      width: 60px;
    }
    &.date {
      width: 140px;
    }
    &.name {
      width: calc(100% - 300px);
    }
  }
}
.full-offer-event,
.tag {
  &.active {
    background: #1F9BDE;
    color: #fff;
  }
}
.message {
  align-items: center;
  display: flex;
  height: 33px;
  margin-top: 7px;
}
</style>
