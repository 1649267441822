<template>
  <div class="header">
    <div class="header-info"
         v-if="!errorPageMessage">
      <span>{{translate('game_NumbersBetting')}}</span>
      <span v-if="!internetConnection"> - {{translate('shop_bettingDisabled')}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  computed: {
    ...mapGetters([
      'translate',
      'errorPageMessage',
      'internetConnection',
    ]),
  },
};
</script>

<style lang="scss" scoped>
  .header {
    align-items: center;
    background-color: #1F9BDE;
    color: #fff;
    display: flex;
    height: 35px;
    padding-left: 35px;
  }
</style>
