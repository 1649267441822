<template>
  <div class="inputs"
       :class="name">
    <div class="inputs-box single">
      <div class="inputs-box-name">{{label}}</div>
      <div class="inputs-box-inputs">
        <InputLottery v-on:update-input="onChange"
                      v-on:next-action="handleAction"
                      :id="`${prefix}0`"
                      :disabled="isDisabled(name)"
                      :max-input-length="maxInputLength"
                      :value="inputValue" />
        <div class="inputs-info"
             v-if="inputInfo">
          {{inputInfo}}
        </div>
      </div>
    </div>
    <div class="outcome-error"
         v-if="outcomeError">
      {{outcomeError}}
    </div>
  </div>
</template>

<script>
import {
  find,
  forEach,
  filter,
} from 'lodash';
import { eventBus } from '@nsftx/games-sdk-js';
import InputLottery from '@/components/InputLottery';
import InputBoxProps from '@/components/InputBoxProps';
import EventBus from '@/store/EventBus';

export default {
  name: 'SingleInputBox',
  components: { InputLottery },
  data() {
    return {
      inputValue: '',
      betNotFound: 'shop_betIsNotFound',
      outcomeError: null,
    };
  },
  props: {
    items: {
      type: [Object, Array],
    },
    searchProp: {
      type: String,
    },
  },
  extends: InputBoxProps,
  mounted() {
    const mountEventFocus = () => {
      document.getElementById('event_0').select();
    };
    /**
     * on mount select event input
     */
    if (this.name === 'event') {
      mountEventFocus();
    }
    EventBus.$on('reset-inputs', () => {
      this.inputValue = '';
      mountEventFocus();
      this.$nextTick(() => {
        this.outcomeError = null;
      });
    });
    EventBus.$on('updateInputs', (data) => {
      const propNameMapper = {
        event: 'lotteryDisplayId',
        bet: 'shortcut',
        outcome: 'shortcut',
      };
      this.inputValue = data[this.name] ? data[this.name][propNameMapper[this.name]] : '';
    });
    EventBus.$on('slaveShown', () => {
      if (this.name !== 'event') return;
      document.getElementById('event_0').select();
    });
    EventBus.$on('selectEvent', () => {
      this.$nextTick(() => {
        document.getElementById('event_0').select();
      });
    });
  },
  methods: {
    /**
     * on input change we are searching for proper object depending props inside evt
     * @param evt (value, name)
     * value - new input value
     * name - name of the box(event, bet, outcome for special)
     * @param name (update name when call from full offer)
     */
    onChange(evt, name = null) {
      this.inputValue = evt.value;
      this.searchForData({
        value: evt.value,
        name: name || this.name,
      });
    },
    /**
     * search for proper object inside events, bets or outcomes
     * @param data
     */
    searchForData(data) {
      if (data.name === 'event') {
        this.$store.dispatch('setFutureBetsActive', false);
      }
      const match = find(this.items, (item) => item[this.searchProp] === data.value);
      this.handleErrorMessage(data.name, data.value === '' || match);
      if (!match) {
        this.resetInputs(data.name);
      } else {
        this.resetInputs(data.name, true);
        this.$store.dispatch('setSelectedEvent', {
          name: data.name,
          data: match,
        });
        if (data.name === 'outcome') {
          const tickets = this.$store.getters['betslip/tickets'];
          const haveOnBetslip = filter(tickets, (ticket) => ticket.id === match.id).length;
          if (haveOnBetslip) {
            this.handleErrorMessage(data.name, false, haveOnBetslip);
          }
        }
      }
    },
    /**
     * Reset inputs depending on name
     * @param name
     * @param shift
     */
    resetInputs(name, shift = false) {
      let data;
      switch (name) {
        case 'event':
          data = !shift
            ? ['event', 'bet', 'outcome', 'bonusBalls']
            : ['bet', 'outcome', 'bonusBalls'];
          break;
        case 'bet':
          data = !shift ? ['bet', 'outcome', 'bonusBalls'] : ['outcome', 'bonusBalls'];
          this.$store.dispatch('setRandomActive', false);
          this.$store.dispatch('updateSelectedNumbers');
          this.$store.dispatch('setBonusBalls');
          break;
        case 'outcome':
          data = !shift ? ['outcome', 'bonusBalls'] : ['bonusBalls'];
          break;
        case 'bonusBalls':
          data = ['bonusBalls'];
          break;
        // no default
      }
      forEach(data, (item) => {
        if (item !== 'bonusBalls') {
          this.$store.dispatch('setSelectedEvent', {
            name: item,
            data: null,
          });
        }
      });
    },
    /**
     * Create error message
     * @param name
     * @param valid
     */
    handleErrorMessage(name, valid, haveOnBetslip = 0) {
      let data = null;
      let outcomeError = null;
      if (!valid) {
        if (name === 'outcome') {
          const key = !haveOnBetslip ? 'shop_outcomeIsNotInOffer' : 'betslip_outcomeIsAlready';
          outcomeError = this.$store.getters.translate(key);
        } else {
          data = {
            name,
            message: name !== 'event' ? this.$store.getters.translate(this[`${name}NotFound`])
              : `${this.$store.getters.translate('shop_matchWithId')} ${this.inputValue} ${this.$store.getters.translate('shop_notfoundAlreadyStarted').toLowerCase()}`,
          };
        }
      }
      this.outcomeError = outcomeError;
      this.$store.dispatch('setSelectEventError', data);
    },
    /**
     * Handler for tab/enter
     * selection on new box
     * or send to betslip
     * @param evt
     */
    handleAction(evt) {
      evt.preventDefault();
      if (evt.target.value) {
        switch (this.name) {
          case 'event':
            document.getElementById('bet_0').select();
            break;
          case 'bet':
            if (!this.$store.getters.selectedEvent.bet) return;
            if (this.$store.getters.selectedEvent.bet.betType === '1232') {
              document.getElementById('bonusBall_0').select();
            } else {
              document.getElementById('outcome_0').select();
            }
            break;
          case 'outcome':
            if (this.$store.getters.selectedEvent.outcome) {
              this.$emit('add-bet');
            }
            break;
          // no default
        }
      } else if (this.$store.getters['betslip/tickets'].length) {
        eventBus.$emit('focusBetslipFooterInput', evt.key);
      }
    },
  },
  watch: {

  },
};
</script>
<style scoped>
.outcome-error {
  margin-top: 7px;
}
</style>
