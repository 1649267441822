<template>
  <div class="betting-area">
    <div class="betting-area-header">
      {{$store.getters.translate('general_ticket')}}
    </div>
    <div class="betting-area-content">
      <InputsBettingArea></InputsBettingArea>
      <PreviewBettingArea></PreviewBettingArea>
    </div>
    <FullOffer v-if="$store.getters.fullOfferActive"/>
  </div>
</template>

<script>
import InputsBettingArea from '@/components/InputsBettingArea';
import PreviewBettingArea from '@/components/PreviewBettingArea';
import FullOffer from '@/components/FullOffer';

export default {
  name: 'BettingArea',
  components: {
    FullOffer,
    PreviewBettingArea,
    InputsBettingArea,
  },
};
</script>

<style lang="scss" scoped>
.betting-area {
  position: relative;
  .betting-area-header {
    align-items: center;
    border-bottom: 1px solid #E8E9EA;
    display: flex;
    height: 40px;
    padding-left: 10px;
    text-transform: capitalize;
  }
  .betting-area-header,
  .betting-area-content {
    padding: 0 10px;
  }
  .betting-area-content {
    display: flex;
    justify-content: space-between;
    > div {
      flex: 1 1 0;
    }
  }
}
</style>
