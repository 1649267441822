<template>
  <div class="standard-numbers">
    <button class="standard-number"
         v-for="numb in maxNumbers"
         :key="`random-numbers-${numb}`"
         :class="{active: isSelected(numb.toString())}"
         @click.stop="$emit('clickOnNumber', numb.toString())"
            :disabled="isDisabled(numb)">
      {{numb}}
    </button>
  </div>
</template>

<script>
import { includes } from 'lodash';

export default {
  name: 'FullOfferMarketsStandardNumbers',
  props: {
    maxNumbers: {
      type: [Number, Array],
      required: true,
    },
    selectedNumbers: {
      type: Array,
      default: () => [],
    },
    pickedNumbers: {
      type: Array,
      default: () => [],
    },
    disableMode: {
      type: Boolean,
      default: true,
    },
    activeStandard: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    isSelected(numb) {
      return this.selectedNumbers.indexOf(numb) >= 0;
    },
    isDisabled(number) {
      let alreadyPicked = false;
      if (this.activeStandard === '1233' && this.disableMode) {
        alreadyPicked = includes(this.pickedNumbers, number.toString());
      }
      return alreadyPicked;
    },
  },
};
</script>

<style lang="scss" scoped>
.standard-numbers {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4px;
  .standard-number {
    align-items: center;
    background: transparent;
    border-radius: 50%;
    border: 1px solid #e0e3e4;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;
    &:hover,
    &.active:not(:disabled) {
      background: #5DAF64;
    }
  }
}
</style>
