<script>
export default {
  name: 'InputBoxProps',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    inputNumbers: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
    },
    numbers: {
      type: String,
      required: false,
    },
    maxNumbers: {
      type: Number,
    },
    inputBoxType: {
      type: String,
      default: 'string',
    },
    inputInfo: {
      type: String,
    },
    maxInputLength: {
      type: Number,
    },
  },
  methods: {
    /**
     * check for disable input
     * @param name
     * @returns {boolean}
     */
    isDisabled(name) {
      let disable;
      switch (name) {
        case 'bet':
          disable = !this.$store.getters.selectedEvent.event;
          break;
        case 'outcome':
          disable = this.$store.getters.selectedEvent.bet?.betType === '1232'
            ? true : !this.$store.getters.selectedEvent.bet;
          break;
        case 'bonusBall':
          disable = !this.$store.getters.selectedEvent.outcome;
          break;
        default:
          disable = false;
          break;
      }
      return disable || !this.$store.getters.internetConnection;
    },
  },
};
</script>
<style lang="scss">
.inputs-box {
  display: flex;
  .inputs-box-name {
    width: 102px;
  }
  .inputs-box-name,
  .inputs-info {
    align-items: center;
    display: flex;
  }
  .inputs-box-name,
  input,
  .inputs-info {
    margin-top: 7px;
    height: 33px;
  }
  .inputs-box-inputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 7px;
  }
  .inputs-info {
    opacity: .3;
    padding-left: 10px;
  }
  &.future {
    .inputs-box-inputs {
      grid-template-columns: repeat(1, 1fr);
      margin-right: 7px;
    }
  }
}
</style>
