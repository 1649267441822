<template>
  <div class="inputs-box future">
    <div class="inputs-box-name">{{label}}</div>
    <div class="inputs-box-inputs">
      <InputLottery :value="futureBetValue"
                    @update-input="updateValue"
                    @next-action="handleTabEnter"
                    :max-input-length="maxNumbers.toString().length"
                    :validate-numbers="validateNumber"
                    :max-numbers="maxNumbers"
                    :id="name"
                    :disabled="disabled || !$store.getters.internetConnection"/>
    </div>
    <div class="future-range-info">1-{{maxNumbers}}</div>
  </div>
</template>

<script>
import InputLottery from '@/components/InputLottery';

export default {
  name: 'FutureBetsInputBox',
  components: { InputLottery },
  data() {
    return {
      futureBetValue: '',
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    futureBetsValue: {
      type: String,
    },
    maxNumbers: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    validateNumber(evt) {
      const placeOfIndicator = evt.target.selectionStart;
      const endOfIndicator = evt.target.selectionEnd;
      const removeIndexes = endOfIndicator - placeOfIndicator;
      const toArray = evt.target.value.split('');
      toArray.splice(placeOfIndicator, removeIndexes, evt.key);
      const joined = toArray.join('');
      const num = +joined;
      return this.maxNumbers >= num
        && joined !== '0';
    },
    handleTabEnter(event) {
      if (event.target.value) {
        this.$emit('add-bet');
      }
    },
    updateValue(event) {
      this.$emit('update-future-bet', +event.value ? event.value : '1');
    },
  },
  mounted() {
    this.futureBetValue = this.futureBetsValue;
    this.$nextTick(() => {
      this.$el.querySelector('#standard-future-bets').select();
    });
  },
};
</script>

<style scoped>
.future-range-info {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 7px;
}
</style>
