<template>
  <div class="markets-standards"
       v-if="markets.length">
    <div class="markets-standards-header">
      <div class="markets-standards-header-title">
        {{activeMarket.shortcut}}. {{getProperStandardTitle}}
      </div>
    </div>
    <div class="outcomes-wrapper">
      <div class="outcome-box"
           :style="{width: `calc(100% / ${Object.keys(activeMarket.outcomes).length})`}"
           v-for="(outcome, keyOutcome) in orderOutcomes"
           :key="`outcomes-${keyOutcome}`">
        {{outcome.shortName }} - {{ outcome.odd.toFixed(2)}}
      </div>
    </div>
    <div class="random-add-wrapper">
      <div class="random-wrapper">
        <div class="markets-standards-title">{{ translate('shop_random') }}:</div>
        <FullOfferMarketsStandardNumbers @clickOnNumber="pickRandomNumbers($event)"
                                         :disable-mode="false"
                                         :max-numbers="Object.keys(activeMarket.outcomes).length"/>
        <button @click.stop="$emit('addToBetslip', { activeMarket })"
                :disabled="validateAddAction">
          {{translate('shop_addToBetslip')}}
        </button>
        <button v-if="!activeEdit"
                @click.stop="removeSelection"
                :disabled="!selectedNumbers.length && !bonusBalls.length">
          {{translate('shop_removeBallsSelection')}}
        </button>
      </div>
    </div>
    <div class="balls-wrapper"
         v-if="activeMarket.betType !== '1232'">
      <FullOfferMarketsStandardNumbers @clickOnNumber="addPickedBall($event)"
                                       :selected-numbers="selectedNumbers"
                                       :max-numbers="activeMarket.maxBalls"
                                       :picked-numbers="bonusBalls"
                                       :active-standard="activeStandard"/>
    </div>
    <div class="additional-balls-wrapper"
    v-if="isHitBonusBall">
      <div class="markets-standards-title">{{translate('nb_bonusBall')}}</div>
      <div class="additional-balls">
        <FullOfferMarketsStandardNumbers @clickOnNumber="addAdditionalBonusBall($event)"
                                         :selected-numbers="bonusBalls.length
                                         ? bonusBalls : []"
                                         :max-numbers="getProperArrayForAdditionalBonus"
                                         :picked-numbers="selectedNumbers"
                                         :active-standard="activeStandard"/>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import FullOfferMarketsStandardNumbers from '@/components/FullOfferMarketsStandardNumbers';
import {
  difference,
  findIndex,
  includes, map,
  orderBy,
  random,
} from 'lodash';
import getRandomNumbers from '../utility/getRandomNumbers';

export default {
  name: 'FullOfferMarketsStandard',
  components: { FullOfferMarketsStandardNumbers },
  data() {
    return {
      bonusBallsTypes: ['834', '1232', '1233'],
    };
  },
  props: {
    markets: {
      type: Array,
    },
    selectedNumbers: {
      type: Array,
      required: true,
    },
    activeStandard: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'translate',
      'bonusBalls',
      'selectedEvent',
      'activeEdit',
    ]),
    activeMarket() {
      const index = findIndex(this.markets, (market) => market.betType === this.activeStandard);
      return this.markets[index];
    },
    getProperStandardTitle() {
      return this.activeMarket.shortName;
    },
    isHitBonusBall() {
      return includes(this.bonusBallsTypes, this.activeStandard);
    },
    orderOutcomes() {
      return orderBy(this.activeMarket.outcomes, 'position');
    },
    getProperArrayForAdditionalBonus() {
      const range = this.selectedEvent.event.tournament.bonusBallRange.split('-');
      const createArray = (start, stop, step) => Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + (i * step),
      );
      return createArray(+range[0], +range[1], 1);
    },
    validateAddAction() {
      let isInvalid;
      switch (this.activeStandard) {
        case '1233':
        case '834':
          isInvalid = this.selectedNumbers.length === 0 || this.bonusBalls.length === 0;
          break;
        case '1232':
          isInvalid = this.bonusBalls.length === 0;
          break;
        default:
          isInvalid = this.selectedNumbers.length === 0;
          break;
      }
      return isInvalid;
    },
  },
  methods: {
    ...mapActions([
      'updateSelectedNumbers',
    ]),
    pickRandomNumbers(numb) {
      let numbers = null;
      if (this.activeStandard !== '1232') {
        numbers = getRandomNumbers(numb,
          this.selectedEvent.event.markets[this.activeStandard].maxBalls);
        this.updateSelectedNumbers({
          value: numbers,
          index: null,
        });
      }
      if (includes(this.bonusBallsTypes, this.activeStandard)) {
        numbers = map(numbers, (num) => +num);
        const ballsArray = this.getProperArrayForAdditionalBonus;
        const bonusBallNumbers = this.activeStandard === '1233' ? difference(ballsArray, numbers) : ballsArray;
        const index = random(0, bonusBallNumbers.length - 1);
        const bonusNumber = bonusBallNumbers[index].toString();
        this.$store.dispatch('setBonusBalls', {
          value: bonusNumber,
          index: 0,
        });
      }
    },
    addPickedBall(numb) {
      const index = this.selectedNumbers.indexOf(numb);
      if (index < 0 && this.selectedNumbers.length === this.activeMarket.maxSelections) {
        return;
      }
      this.updateSelectedNumbers({
        value: index >= 0 ? null : numb,
        index: index >= 0 ? index : this.selectedNumbers.length,
      });
    },
    addAdditionalBonusBall(numb) {
      const willBeRemoved = this.bonusBalls[0] === numb;
      this.$store.dispatch('setBonusBalls', {
        value: willBeRemoved ? null : numb,
        index: 0,
      });
    },
    removeSelection() {
      this.$store.dispatch('updateSelectedNumbers');
      this.$store.dispatch('setBonusBalls');
    },
  },
  watch: {
    activeStandard() {
      this.removeSelection();
    },
  },
};
</script>

<style lang="scss" scoped>
.markets-standards {
  .markets-standards-header {
    background: #2E3743;
    color: #fff;
    justify-content: space-between;
    .markets-standards-header-bonus {
      .bonus-ball-checkbox {
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        margin-left: 5px;
        width: 20px;
        &.active {
          background-color: #ffc107;
        }
      }
    }
  }
  .markets-standards-header,
  .markets-standards-header-bonus,
  .outcomes-wrapper,
  .random-wrapper {
    align-items: center;
    display: flex;
  }
  .outcomes-wrapper {
    .outcome-box {
      font-size: 13px;
    }
  }
  .random-wrapper {
    > button {
      background-color: #1F9BDE;
      border-radius: 3px;
      color: #fff;
      margin-left: 6px;
      padding: 6px 12px;
      &[disabled] {
        opacity: .6;
      }
    }
  }
  .additional-balls-wrapper {
    .markets-standards-title {
      margin-bottom: 10px;
    }
  }
  .markets-standards-header,
  .balls-wrapper,
  .random-add-wrapper,
  .outcome-box,
  .additional-balls-wrapper {
    padding: 6px;
  }
  .outcome-box {
    &:not(&:last-child) {
      border-right: 1px solid #e0e3e4;
    }
  }
  .random-add-wrapper {
    .markets-standards-title {
      margin-right: 5px;
    }
  }
  .random-add-wrapper,
  .balls-wrapper,
  .additional-balls-wrapper {
    border-top: 0;
  }

  .random-add-wrapper,
  .balls-wrapper,
  .additional-balls-wrapper,
  .outcomes-wrapper {
    border: 1px solid #e0e3e4;
  }
}
</style>
