<template>
  <div class="inputs-box group"
       :class="name"
       :id="name"
       v-if="!$store.getters.randomActive">
    <div class="inputs-box-name">{{label}}</div>
    <div class="inputs-box-inputs">
      <InputLottery v-for="(input, index) in inputNumbers"
             :key="index"
             @update-input="onChange"
             v-on:next-action="handleAction($event, index)"
             :focus="selectPreviousEmptyInput"
             :id="`${prefix}${index}`"
             :max-input-length="maxInputLength"
             :max-numbers="maxNumbers"
             :value="$store.getters[numbers][index] || ''"
             :validate-numbers="numbersValidation"
             :input-index="index"
             :disabled="disabled || !$store.getters.internetConnection"/>
    </div>
    <div class="inputs-info"
         v-if="inputInfo">
      {{inputInfo}}
    </div>
  </div>
</template>

<script>
import {
  forEach,
  filter,
  remove,
} from 'lodash';
import InputBoxProps from '@/components/InputBoxProps';
import InputLottery from '@/components/InputLottery';

export default {
  name: 'GroupInputBox',
  components: { InputLottery },
  extends: InputBoxProps,
  methods: {
    /**
     * on change input value we will add/reset value in proper array
     * @param evt
     */
    onChange(evt) {
      const name = this.name === 'outcome' ? 'updateSelectedNumbers' : 'setBonusBalls';
      this.$store.dispatch(name, {
        value: evt.value,
        index: evt.index,
      });
      const checkInputs = this.checkInputsHaveValue();
      const previewOutcomeLength = this.inputNumbers - checkInputs.emptyInputs;
      if (previewOutcomeLength > 0) {
        this.$store.dispatch('setSelectedEvent', {
          name: this.name,
          data: filter(this.$store.getters.selectedEvent.bet.outcomes,
            (outcome) => +outcome.shortcut === previewOutcomeLength)[0],
        });
      } else {
        this.$store.dispatch('setSelectedEvent', {
          name: this.name,
          data: null,
        });
      }
    },
    /**
     * validate for value depending on indicator
     * @param evt
     * @returns {boolean}
     */
    numbersValidation(evt) {
      const placeOfIndicator = evt.target.selectionStart;
      const endOfIndicator = evt.target.selectionEnd;
      const removeIndexes = endOfIndicator - placeOfIndicator;
      const toArray = evt.target.value.split('');
      toArray.splice(placeOfIndicator, removeIndexes, evt.key);
      const joined = toArray.join('');
      const num = +joined;
      return this.maxNumbers >= num
        && joined !== '0';
    },
    /**
     * On focus select previous empty inputs
     * loop depending on index
     * @param index
     */
    selectPreviousEmptyInput(index) {
      let i = 0;
      const inputs = this.getInputs();
      for (i; i < index; i += 1) {
        if (!inputs[i].value) {
          inputs[i].select();
          break;
        }
      }
    },
    /**
     * Select first empty input
     * index start of loop, max input length
     * @param index
     */
    selectFirstEmpty(index) {
      let i;
      let isChanged = false;
      const inputs = this.getInputs();
      for (i = index; i < inputs.length; i += 1) {
        if (!inputs[i].value) {
          isChanged = true;
          inputs[i].select();
          break;
        }
      }
      if (!isChanged) {
        inputs[i + 1].select();
      }
    },
    /**
     * check that inputs have value
     * @returns {{valid: boolean, inputs: NodeListOf<HTMLElementTagNameMap[string]>,
     * index: null, emptyInputs: number}}
     */
    checkInputsHaveValue() {
      let isInvalid = false;
      let index = null;
      let numOfEmptyInputs = 0;
      const inputs = this.getInputs();
      forEach(inputs, (input, key) => {
        if (!input.value) {
          if (!isInvalid) {
            isInvalid = true;
            index = key;
          }
          numOfEmptyInputs += 1;
        }
      });
      return {
        valid: !isInvalid,
        index,
        inputs,
        emptyInputs: numOfEmptyInputs,
      };
    },
    /**
     * Handle tab/enter
     * remove empty array value
     * check for duplicate numbers
     * select bonus balls box
     * send to betslip - this is only comment, but prepared
     * @param evt
     * @param index
     */
    handleAction(evt, index = null) {
      evt.preventDefault();
      this.removeEmptyArrayValue();
      this.$nextTick(() => {
        if (this.$store.getters[this.numbers].length === 0) return;
        const haveInNumbers = this.$store.getters[this.numbers].indexOf(evt.target.value);
        if (haveInNumbers < 0 || haveInNumbers === index) {
          if ((index + 1) < this.inputNumbers && evt.target.value) {
            if (this.$store.state.activeEdit && evt.code === 'Enter') {
              const nextId = `${evt.target.id.split('_')[0]}_${index + 1}`;
              document.getElementById(nextId).select();
            } else {
              this.selectFirstEmpty(index);
            }
          } else {
            const haveDuplicate = this.checkDuplicate(this.$store.getters[this.numbers]);
            if (haveDuplicate) {
              this.$store.dispatch('busServiceSendMessage', {
                action: 'Dialog.ShowModal',
                data: {
                  action: '7S:Dialog.ShowModal',
                  message: this.$store.getters.translate('shop_removeDuplicateNumbers'),
                  type: 'warning',
                  delay: 3000,
                },
              });
            } else if (this.$store.getters.selectedEvent.bet.betType === '834'
              || this.$store.getters.selectedEvent.bet.betType === '1233') {
              if (this.name === 'outcome') {
                document.getElementById('bonusBall_0').select();
              } else if (this.$store.getters.selectedEvent.outcome) {
                this.$emit('add-bet');
              } else {
                document.getElementById('outcome_0').select();
              }
            } else if (this.$store.getters.selectedEvent.bet.betType === '1232') {
              this.$store.dispatch('setSelectedEvent', {
                name: 'outcome',
                data: this.$store.getters.selectedEvent.bet.outcomes['1232/wns:hit:1:1'],
              });
              this.$emit('add-bet');
            } else {
              this.$emit('add-bet');
            }
          }
        }
      });
    },
    /**
     * get all inputs
     * @returns {NodeListOf<HTMLElementTagNameMap[string]>}
     */
    getInputs() {
      return document.getElementById(this.name).querySelectorAll('input');
    },
    /**
     * remove empty value for proper array
     */
    removeEmptyArrayValue() {
      const numbers = this.$store.getters[this.numbers].slice();
      remove(numbers, (num) => !num.length);
      const name = this.name === 'outcome' ? 'updateSelectedNumbers' : 'setBonusBalls';
      this.$store.dispatch(name, {
        value: numbers,
        index: null,
      });
    },
    /**
     * check for duplicate
     * @param items
     * @returns {*}
     */
    checkDuplicate(items) {
      const haveDuplicate = (num, index) => items.indexOf(num) !== index;
      return items.some(haveDuplicate);
    },
  },
  /**
   * reset selected numbers and bonus balls on destroyed
   */
  beforeDestroy() {
    this.$store.dispatch('updateSelectedNumbers');
    this.$store.dispatch('setBonusBalls');
  },
};
</script>
